import * as React from "react";
import { Box,  List, ListItem, Grid } from "@material-ui/core";
import FeaturedProduct from "../components/FeaturedProduct";
import { ApplicationState } from "../types/Master";
import AlertMessage from "./AlertMessage";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import UseTranslation from "../services/UseTranslation";
const mapStateToProps = (state: ApplicationState) => ({
    kbArticles: state.kbarticles
}); 
const translation = UseTranslation();

const Home = (props: any) => {
    const { kbArticles } = props;
    const itemCount = 12; 
    return (
        <React.Fragment>
            <Box className="py-4">
                <Container>
                    <AlertMessage />
                </Container>
            </Box>
            <Box style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <h3 className="light-grey-color-02 bi-bold-20">
                    {translation.avoidWaitingTime}
                </h3>
            </Box>
            <Container>
                <Box className="row py-2">
                    <Box className="col-xs-12 col-sm-10 m-auto">

                        <FeaturedProduct />

                    </Box>
                </Box>
            </Container>
            <Box className="mt-2 article-homepage">
                <Container>
                    <Box className="text-center mt-4">
                        <h3 className="light-grey-color-02 bi-bold-20 color-blue">
                            {translation.FaqTitle}
                        </h3>
                    </Box>
                    <Box className="row">
                        <Grid className="col-sm-8 offset-sm-2">
                            <List className="row row-cols-sm-2">
                                {kbArticles && kbArticles.filter((item: any) => item.Showontop === true)
                                    .sort((a: any, b: any) =>
                                        new Date(a.Modifiedon) < new Date(b.Modifiedon) ? 1 : -1
                                    )
                                    .slice(0, itemCount).map((item: any, index: any) => {
                                        return (
                                            <ListItem>
                                                <Link
                                                    to={"/articledetails/" + item.PortalUrl}
                                                    className="view-all pb-2">
                                                    {renderHtml(item.Title)}
                                                </Link>
                                            </ListItem>
                                        );
                                    })}
                            </List>
                        </Grid>
                    </Box>
                   
                    <Box className="text-center my-4">
                        <Link to="/ArticlesList/all" className="btn footer-button bi-btn-orangeBorder">
                            {translation.viewAll} {translation.questions}
                        </Link>
                    </Box>
                  
                </Container>
            </Box>
        </React.Fragment>
    );
};
function renderHtml(val: string) {
    return <div dangerouslySetInnerHTML={{ __html: val }} />;
}
export default connect(mapStateToProps)(Home);
