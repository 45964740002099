import * as React from "react";
import {
    Grid,
    List,
    ListItemText,
    ListItemIcon,
} from "@material-ui/core";
import ListIcon1 from '../images/Andetbb-s.svg';
import ListIcon2 from '../images/Profilogemailsbb-s.svg';
import ListIcon3 from '../images/SlgdinbilpBilbasenbb-s.svg';
import ListIcon4 from '../images/KbdinbilpBilbasenbb-s.svg';
import ListIcon5 from '../images/Svindelogsikkerhedbb-s.svg';
import UseTranslation from "../services/UseTranslation";
const translation = UseTranslation();
import { Link } from "react-router-dom";
import { categories } from "../types/Master";

class FeaturedProduct extends React.Component {
    render() {
        return (
            <Grid item xs={12} className="featured_product">
                <List className="row bi-flex--justifyCenter" component={"div"}>
                    <Link
                        className="text-center flex-column col-4 mb-2"
                        to={"/articlesList/" + categories["Andet"]} >
                        <ListItemIcon
                            style={{ display: "inline-block", textAlign: "center" }}>
                            <img
                                src={ListIcon1}
                                className="img-fluid"
                            />
                        </ListItemIcon>
                        <ListItemText primary="ANDET" />
                    </Link>
                    <Link
                        className="text-center flex-column col-4 mb-2"
                        to={"/articlesList/" + categories["Profil og emails"]}>
                        <ListItemIcon
                            style={{ display: "inline-block", textAlign: "center" }}>
                            <img
                                src={ListIcon2}
                                className="img-fluid"
                            />
                        </ListItemIcon>
                        <ListItemText primary="PROFIL OG E-MAILS" />
                    </Link>
                </List>

                <List className="row" component={"div"}>
                    <Link
                        className="text-center flex-column col-4"

                        to={"/articlesList/" + categories.Saelgbil}
                    >
                        <ListItemIcon
                            style={{ display: "inline-block", textAlign: "center" }}
                        >
                            <img
                                src={ListIcon3}
                                className="img-fluid"
                            />
                        </ListItemIcon>
                        <ListItemText primary={translation.Saelgbil} />
                    </Link>
                    <Link
                        className="text-center flex-column col-4"
                        to={"/articlesList/" + categories["Kobbil"]}>
                        <ListItemIcon
                            style={{ display: "inline-block", textAlign: "center" }} >
                            <img
                                src={ListIcon4}
                                className="img-fluid"
                            />
                        </ListItemIcon>
                        <ListItemText primary={translation.Kobbil} />
                    </Link>
                    <Link
                        className="text-center flex-column col-4"
                        to={"/articlesList/" + categories["Svindel og sikkerhed"]}>
                        <ListItemIcon
                            style={{ display: "inline-block", textAlign: "center" }} >
                            <img
                                src={ListIcon5}
                                className="img-fluid"
                            />
                        </ListItemIcon>
                        <ListItemText primary="SVINDEL OG SIKKERHED" />
                    </Link>
                </List>
            </Grid>
        );
    }
}

export default FeaturedProduct;
