import * as React from "react";
import { Box } from "@material-ui/core";
import Articles from "./Articles";
import EmailForm from "./EmailForm";
import { Container } from "reactstrap";
import AlertMessage from "./AlertMessage";

class ContactSupport extends React.Component {
    render() {
        return (
            <Box className="contact-support-main">
                <Container>
                    <Box className="py-4">
                        <Container>
                            <AlertMessage />
                        </Container>
                    </Box>
                    <Box className="row pb-5">
                        <Box className="col-12 col-sm-6">
                            <EmailForm />
                        </Box>

                        <Box className="col-12 col-sm-5 offset-sm-1 mt-xs-4">
                            <Articles />
                        </Box>
                    </Box>
                </Container>
            </Box>
        );
    }
}

export default ContactSupport;
