export default {
  SearchTitle: "Hvordan kan vi hjælpe?",
  SearchPlaceholder: "Stil et spørgsmål eller søg på et emne",
  FaqTitle: "Ofte stillede spørgsmål",
  Footerlabel: "Vi har åbent alle dage kl. 8-22",
  attachment: "Attachment",
  backToCases: "Back to Cases",
  businessPhone: "Business Phone",
  cancel: "Cancel",
  cases: "Cases",
  callFormHeading:
    "Ring mig op (Bemærk at vi ringer til dig fra skjult nummer)",
  callFormSubHeading: "Vi bestræber os på at ringe tilbage inden 5 minutter",
  emailFormReturnTerms:
    "Jeg er indforstået med Bilbasen persondatapolitikker, som er beskrevet ",
  loadmore: "Indlæs mere",
  noResults: "Ingen resultater",
  questions: "spørgsmål",
  scamAmount: "Svindlet beløb",
  sellerName: "Sælgers navn",
  sellerAddress: "Sælgers adresse",
  sellerPhone: "Sælgers telefonnummer",
  sellerEmail: "Sælgers e-mail",
  InvalidEmailAddress: "Ugyldig emailadresse",
  InvalidUrl: "Ugyldig URL",
  Kobbil: "Køb bil",
  Saelgbil: "Sælg bil",
  avoidWaitingTime: "Undgå ventetid - find selv svaret",
  BankoverPay: "Bankoverførsel",
  Thankyou: "Tak for din besked.",
  ReturnASAP: "Vi vender tilbage snarest muligt.",
  OfflineASAP:
    "Vi har lige nu lukket for telefonsupport - Kontakt os i stedet via e-mail, og vi vender tilbage hurtigst muligt",
  emailCustomerService: "Send en email til Kundeservice",
  contactCustomerService: "Kontakt kundeservice",
  callme: "Ring mig op",
  cardealer: "Bilforhandler-support, kontakt",
  backToHomepage: "< Tilbage til Bilbasen forside",
  customerservice: "Kundeservice",
  viewAll: "Vis alle",
  FileSizeExceed: "Filstørrelsen må ikke overstige 20 MB.",
  close: "Luk",
  errorMessage:
    "Undskyld! vi kan ikke fortsætte med denne handling, da godkendelsen ikke er gyldig.",
  category: "Emne",
  required: "Udfyld venligst dette felt",
  back: "< Tilbage",
  recaptchafailuremsg:
    "Der opstod desværre en sikkerhedsmæssig fejl ved din supportadmodning og du bedes prøve igen (og hvis det ikke virker, prøv da gerne en anden browser eller computer/telefon)",
  techinicalerrormsg:
    "Din supportanmodning er desværre fejlet af tekniske årsager. Du bedes prøve igen.",
  platform: "Vælg platform",
};
