import * as React from "react";
import { Container } from "reactstrap";
import { Box, Typography } from "@material-ui/core";
import "./index.css";
import UseTranslation from "../services/UseTranslation";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "../types/Master";
import { action_types, SET_SUCCESS } from "../types/ActionTypes";
import { Dispatch } from "redux";
import { useEffect } from "react";
import { GetConfigInfo } from "../services/CustomerService";

const mapStateToProps = (state: ApplicationState) => ({
  formSuccess: state.formSuccess,
});

const mapDispatchToProps = (dispatch: Dispatch<action_types>) => {
  return {
    SetSuccess: (val: boolean) =>
      dispatch({ type: "SET_SUCCESS", payload: val }),
    SetCallSuccess: (val: boolean) =>
      dispatch({ type: "SET_CALL_SUCCESS", payload: val }),
  };
};

const translation = UseTranslation();

const Footer = (props: any) => {
  const [isOffline, setIsOffline] = React.useState<boolean>(false);
  const onemailsubmit = () => {
    props.SetSuccess(false);
  };

  const oncallsubmit = () => {
    props.SetCallSuccess(false);
  };

  useEffect(() => {
    GetConfigInfo()
      .then((res) => {
        if (res) {
          if (res != null) {
            if (res.EnableChatWidget == "true") {
              const script = document.createElement("script");
              script.setAttribute("id", "Microsoft_Omnichannel_LCWidget");
              script.setAttribute("src", res.MsChatWidgetSource);
              script.setAttribute("type", "text/javascript");
              script.setAttribute("data-app-id", res.MsChatWidgetAppID);
              script.setAttribute(
                "data-lcw-version",
                res.MsChatWidgetLCWVersion
              );
              script.setAttribute("data-org-id", res.MsChatWidgetOrgID);
              script.setAttribute("data-org-url", res.MsChatWidgetOrgURL);
              document.head.appendChild(script);
            } else {
              setIsOffline(true);
            }
          }
        }
      })
      .catch((ex) => {
        console.error(ex);
      });
  }, []);

  return (
    <React.Fragment>
      {isOffline && (
        <div className="bi-fixed">
          <div className="oclcw-chatbutton">
            <div className="oclcw-chaticon-container">
              <img
                className="oclcw-chaticon"
                alt=""
                src="https://oc-cdn-ocprod.azureedge.net/livechatwidget/images/chatIcon.svg"
              />
            </div>
            <div className="oclcw-chatbutton-title">
              <div
                className="oclcw-t1"
                aria-label="Chat Offline"
                title="Chat Offline"
              >
                Chat Offline
              </div>
              <div
                className="oclcw-t2"
                aria-label="Vi er offline"
                title="Vi er offline"
              >
                Vi er offline
              </div>
            </div>
          </div>
        </div>
      )}

      <Box className="footer-section py-4">
        <Container>
          <footer className="row">
            <Box className="col-xs-12 col-sm-6">
              <h1>{translation.contactCustomerService}</h1>
              <Typography>{translation.Footerlabel}</Typography>
              <Typography className="mt-4 pt-4 bi-bold-14">
                {translation.cardealer}
                <a
                  href="https://www.bilinfo.dk/"
                  target="blank"
                  className="text-orange"
                >
                  Bilinfo
                </a>
              </Typography>
            </Box>
            <Box className="col-xs-12 col-sm-6 mt-4 bi-footer-alignRight">
              <Link
                to="/contactsupport"
                onClick={onemailsubmit}
                className="footer-button mr-2"
              >
                Email
              </Link>
              <Link
                to="/callmeback"
                onClick={oncallsubmit}
                className="footer-button"
              >
                {translation.callme}
              </Link>
            </Box>
          </footer>
        </Container>
      </Box>
    </React.Fragment>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
